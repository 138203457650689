<template>
  <div id="mArticle" class="article_list">
    <div class="head_section">
      <h2 id="dkBody" class="tit_section">결재선 관리</h2>
    </div>
    <div class="box_sorting">
      <strong class="screen_out">문서 검색하기</strong>
      <ul class="list_sorting">
        <li class="item_sort">
          <em class="tit_txt">활성화 여부</em>
          <div class="cont_sorting">
            <div class="box_choice">
              <div class="item_choice">
                <input
                  id="activeCheck0"
                  v-model="useYn"
                  type="radio"
                  class="inp_choice"
                  name="activeCheck"
                  checked=""
                /><!-- disabled 상태시 disabled 속성 추가 -->
                <label class="lab_choice" for="activeCheck0">
                  <span class="ico_account ico_rdo" />전체
                </label>
              </div>
              <div class="item_choice">
                <input
                  id="activeCheck1"
                  v-model="useYn"
                  type="radio"
                  class="inp_choice"
                  value="Y"
                  name="activeCheck"
                /><!-- disabled 상태시 disabled 속성 추가 -->
                <label class="lab_choice" for="activeCheck1">
                  <span class="ico_account ico_rdo" />활성화
                </label>
              </div>
              <div class="item_choice">
                <input
                  id="activeCheck2"
                  v-model="useYn"
                  type="radio"
                  class="inp_choice"
                  value="N"
                  name="activeCheck"
                /><!-- disabled 상태시 disabled 속성 추가 -->
                <label class="lab_choice" for="activeCheck2">
                  <span class="ico_account ico_rdo" />비활성화
                </label>
              </div>
            </div>
          </div>
        </li>
        <li class="item_sort">
          <em class="tit_txt">계정명</em
          ><!-- 2019-08-22 수정 -->
          <div class="cont_sorting">
            <!-- 계정 선택 (뎁스X) -->
            <!-- opt_comm -->
            <div class="opt_comm">
              <!-- 비활성일 때 disabled 클래스 추가 / 100px 너비 타입은 type_short 클래스 추가 / 읽음 표시 타입일 때 type_readcheck 클래스 추가 -->
              <Input :value.sync="payAccountName" @onEnterKey="keypress" />
            </div>
          </div>
        </li>
      </ul>
      <!-- // list_sorting -->
      <div class="wrap_btn">
        <button type="button" class="btn_large btn_primary" @click.prevent="setPaging(1)">
          조회
        </button>
      </div>
    </div>
    <div class="area_list">
      <div class="head_tbl">
        <div class="wrap_btn">
          <div class="pos_left">
            <!--            <button type="button" class="btn_comm bg_type3" @click.prevent="syncAccountList">계정정보 동기화</button>-->
            <button type="button" class="btn_medium btn_tertiary" @click.prevent="payAccountEdit()">
              <span class="ico_account ico_cross_w" />결재선 등록
            </button>
          </div>
        </div>
      </div>
      <div class="tbl_comm tbl_list">
        <table>
          <caption class="ir_caption">
            결과 목록 표
          </caption>
          <colgroup>
            <col style="width: 92px" />
            <col style="width: 204px" />
            <col />
            <col style="width: 216px" />
          </colgroup>
          <thead>
            <tr>
              <th scope="col">순번</th>
              <th scope="col">활성화 여부</th>
              <th scope="col">계정</th>
              <th scope="col">
                <a href="javascript:void(0);">업데이트 일시<span class="ico_account" /></a
                ><!-- 클릭시 sort_on 클래스 토글 -->
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(payAccount, idx) in payAccountList" :key="idx">
              <!-- <td>{{payAccount.rownum | toInteger}}</td> -->
              <td>
                {{ (newIdx > 0 ? newIdx + idx + 1 : idx + 1) | toInteger }}
              </td>
              <td>{{ useName[idx] }}</td>
              <td class="cell_left">
                <a
                  href="javascript:void(0);"
                  class="link_detail"
                  @click.prevent="payAccountEdit(payAccount.aprvlLineAccntNo)"
                  >{{ payAccount.name }}</a
                >
              </td>
              <td>{{ modDate[idx] }}</td>
            </tr>
            <tr v-if="payAccountList.length == 0">
              <td colspan="4">조회된 문서가 없습니다.</td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- // tbl_comm -->
      <div
        class="paging_comm"
        :class="!payAccountList.length || payAccountList.length === 0 ? 'paging_none' : ''"
      >
        <paginate
          v-model="paginateConfig.pageCurrent"
          :click-handler="setPaging"
          :container-class="paginateConfig.ulClass"
          :prev-text="paginateConfig.prevText"
          :next-text="paginateConfig.nextText"
          :page-range="paginateConfig.pageRange"
          :margin-pages="paginateConfig.marginPage"
          :page-count="paginateConfig.pageCount"
        />
      </div>
      <!-- // paging_comm -->
    </div>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";
import { getDateStringWithTime } from "@/utils/dateUtils";

import CommOpt from "@/_approval/components/common/CommOpt";
import CommInpSearch from "@/_approval/components/common/CommInpSearch";
import Input from "@/_approval/components/common/input/Input";
import { default as CommLayerMixin } from "@/_approval/mixins/common/commLayer.js";
import { default as CommFlatpickrMixin } from "@/_approval/mixins/common/commFlatpickr.js";
import { default as CommMomentMixin } from "@/_approval/mixins/common/commMoment.js";
import { default as CommPaginateMixin } from "@/_approval/mixins/common/commPaginate.js";
import Vue from "vue";

// Vue.prototype.$payAccountBus = new Vue()

export default {
  name: "PayAccountList",
  components: {
    CommOpt,
    CommInpSearch,
    Input,
  },
  mixins: [CommLayerMixin, CommFlatpickrMixin, CommMomentMixin, CommPaginateMixin],
  data() {
    return {
      empNo: "",
      useYn: null,
      payAccountName: "",
      payAccountList: [],
      newIdx: 0,
    };
  },
  beforeRouteEnter(to, from, next) {
    // 상세화면으로 오는 게 아니면 폼 양식 데이터 삭제
    if (from.name !== "payAccountEdit") {
      localStorage.removeItem("adminForm");
    }
    next(true);
  },
  computed: {
    useName: function () {
      return this.payAccountList.map(function (item) {
        return item.useYn ? "활성화" : "비활성화";
      });
    },
    modDate: function () {
      return this.payAccountList.map(function (item) {
        if (!item.modDate) return "";
        return getDateStringWithTime(item.modDate);
      });
    },
  },
  created() {
    this.paginateConfig.pageCurrent = 1;
    this.setPaging(this.paginateConfig.pageCurrent);

    // this.getData();
    // if (localStorage.adminForm) {
    //   let param = JSON.parse(localStorage.adminForm)
    //   this.payAccountName = param.payAccountName !== null ? param.payAccountName : null
    //   this.useYn = param.useYn !== null ? param.useYn : null
    //   this.paginateConfig.pageCurrent = param.page
    // }
    // this.setPaging(this.paginateConfig.pageCurrent)
  },
  methods: {
    async getData(pageNo) {
      // let localUseYn = this.useYn ? 'Y' : 'N'
      const path = `${this.$apiPath.APRVL_LINE_ACCONT}`;
      const params = {
        page: pageNo,
        size: 10,
        name: this.payAccountName,
        useYn: this.useYn,
      };
      const result = await ApiService.shared.get(path, { params });

      if (!result.data) {
        this.payAccountList = [];
        return;
      }

      if (pageNo > 1) {
        this.newIdx = 10 * (pageNo - 1);
      } else {
        this.newIdx = 0;
      }

      this.payAccountList = result.data;
      this.paginateConfig.pageCount = Number(result.total);
    },
    // selectPayAccountList (pageNo) {
    //   axios.post('/api/admin/selectPayAccountList', {
    //     countPerPage: 10,
    //     pageNo: pageNo,
    //     payAccountName: this.payAccountName,
    //     useYn: this.useYn
    //   })
    //     .then((res) => {
    //       this.payAccountList = res.data.payAccountList
    //       this.paginateConfig.pageCount = res.data.totalPage
    //     })
    //     .catch((error) => {
    //       console.log(error)
    //     })
    // },
    keypress(key) {
      if (key.which === 13) {
        this.getData(this.paginateConfig.pageCurrent);
      }
    },
    setPaging(page) {
      this.paginateConfig.pageCurrent = page; // 선택한 페이지 번호 값 세팅 (필수)
      this.getData(page);
      // this.selectPayAccountList(page)
    },
    payAccountEdit(aprvlLineAccntNo) {
      if (aprvlLineAccntNo) {
        let adminForm = {};
        adminForm.payAccountName = this.payAccountName;
        adminForm.vatDisplayYn = this.vatDisplayYn;
        adminForm.memberAddUseYn = this.memberAddUseYn;
        adminForm.useYn = this.useYn;
        adminForm.page = this.paginateConfig.pageCurrent;
        localStorage.adminForm = JSON.stringify(adminForm);
        this.$router.push({
          name: "payAccountEdit",
          query: { aprvlLineAccntNo: aprvlLineAccntNo },
        });
      } else {
        this.$router.push({ name: "payAccountEdit" });
      }
    },
  },
};
</script>
